.product-link {
  width: 100%;
  display: flex;
  flex-direction: column;
  background: $light-gray;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border-radius: 10px;
  transition: all 0.5s;
  &:hover {
    background-color: $light-tirkizna;
  }
  h3 {
    font-size: 1.2rem;
    color: $gray;
  }
  img {
    width: 4rem;
  }
}
.important {
  background-color: $light-tirkizna;
}
