* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
  text-decoration: none;
  scroll-behavior: smooth;
}

body {
  width: 100%;
  overflow-x: hidden;
  margin: 0;
  background-color: #fff;
}

.section {
  width: 100%;
  padding: 10px 20px;
  margin: 10px auto;
  text-align: center;
  overflow-x: hidden;
  h2 {
    width: 100%;
    margin: 15px 0 5px;
    font-size: 1.4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    span {
      color: $light-tirkizna;
      margin-bottom: 3px;
      font-weight: 600;
    }
  }
  h3 {
    font-size: 1rem;
    color: $light-tirkizna;
    font-weight: 600;
  }
}

.margin-top {
  margin-top: 15vh;
}

.grid-2 {
  width: 100%;
  margin: 20px auto;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
}
