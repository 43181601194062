.item-box {
  border-radius: 15px;
  box-shadow: 0px 0px 5px 2px #19aa7540;
  padding: 20px;
  text-align: left;
  display: block;
  position: relative;
  width: 100%;
  h3 {
    font-size: 1.4rem;
    color: #222;
  }
  .discount-box {
    width: fit-content;
    padding: 5px 15px;
    border-radius: 5px;
    color: #fff;
    font-weight: 600;
    margin: 5px 0;
    background-color: $light-tirkizna;
  }
  .discount {
    font-size: 0.8rem;
    text-decoration: line-through;
    color: $light-tirkizna;
  }
  p {
    margin-top: -4px;
    font-size: 1.4rem;
    font-weight: 600;
    color: #555;
  }
  .trending-img {
    position: relative;
    padding: 10px;
    img {
      position: relative;
      width: 100%;
      z-index: 2;
    }
    /*     &::before {
      content: "";
      position: absolute;
      left: 50%;
      top: 50%;
      width: 260px;
      height: 260px;
      transform: translate(-50%, -50%);
      background-color: #7ae9c054;
      border-radius: 50%;
      z-index: 1;
    } */
  }

  .wishlist-btn,
  .cart-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    border: none;
    background: $light-gray;
    border-radius: 50%;
    font-size: 1.8rem;
    width: 2.2rem;
    height: 2.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $light-tirkizna;
    box-shadow: 0px 0px 5px 2px #44444423;
  }
  .clicked {
    background-color: rgb(22, 235, 200);
    span {
      margin-top: 2px;
      color: #fff;
    }
  }
  .cart-btn {
    top: 55px;
  }
}

/* *****SHOPPING CART***** */
.wishcart-box {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 100%;
  height: 6vh;
  bottom: 0;
  left: 0;
  background-color: $light-tirkizna;
  z-index: 10;
  padding: 10px 15px;

  .open-btn {
    position: relative;
    margin-top: -3vh;
    z-index: 11;
    border: none;
    background: $light-tirkizna;
    border-radius: 50%;
    font-size: 2rem;
    width: 3.2rem;
    height: 3.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px -4px 5px 2px #44444417;

    color: #222;
    span {
      font-size: 1.8rem;
    }
    .count {
      position: absolute;
      top: -2px;
      right: -2px;
      font-weight: 600;
      font-size: 0.9rem;
      height: 1.4rem;
      width: 1.4rem;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      background-color: #95fc6e;
      box-shadow: 0px -4px 5px 2px #44444417;
    }
  }
}

.shopping-cart,
.wishlist {
  position: fixed;
  overflow-y: auto;
  left: 5%;
  top: 20vh;
  width: 90%;
  padding: 20px 10px 60px;
  z-index: 10;
  border-radius: 10px;
  background-color: #f8f7f7;
  box-shadow: 0px 0px 5px 2px #44444441;
  .close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    border: none;
    background: none;
    font-size: 1.1rem;
  }
}

.wishlist,
.shopping-cart {
  z-index: 11;
  h3 {
    font-size: 1.2rem;
    width: 60%;
    margin: 5px auto;
    font-weight: 800;
  }
  ul {
    list-style: none;
    li {
      .product-img {
        position: relative;
        width: 90%;
        margin: 5px auto;
        padding: 15px 10px;
        display: grid;
        grid-template-columns: auto 2fr 0.5fr;

        text-align: left;
        img {
          width: 4rem;
        }
        .product-prices {
          text-align: right;

          p {
            text-align: left;
            font-size: 1.1rem;
            font-weight: 500;
            margin-right: 5px;
          }
          .discount {
            font-size: 0.8rem;
            margin-right: 5px;
            text-decoration: line-through;
            color: $light-tirkizna;
          }
          h4 {
            font-size: 1.2rem;
          }
        }
        .quantity-controls {
          position: absolute;
          left: 70px;
          bottom: 0px;
          display: flex;
          align-items: center;
          button {
            height: 1.4rem;
            width: 1.4rem;
            border: 2px solid $light-tirkizna;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 5px;
            font-size: 1rem;
            margin: 0 5px;
            border-radius: 50%;
          }
        }
      }
      .remove-btn {
        border: none;
        color: #222;
        background: none;
        margin-left: 20px;
        font-size: 1rem;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
  .total-price {
    margin: 20px 0;
    font-weight: 600;
    .shipping-price {
      font-size: 1rem;
      font-weight: 500;
    }
    .price {
      font-size: 1.1rem;
      font-weight: 600;
    }
  }
  .checkout-btn {
    font-size: 1rem;
    padding: 10px 15px;
    border-radius: 10px;
    background-color: #ddd;
    border: none;
    font-weight: 600;
  }
}
