header {
  position: fixed;
  background-color: #fff;
  top: 0;
  left: 0;
  width: 100%;
  padding: 15px 15px 0px;
  z-index: 10;
  border-bottom: 2px solid $light-tirkizna;
  transition: all 0.5s;
  nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .logo {
      width: 5vh;
    }
    .header-btn-box {
      display: flex;
      gap: 10px;
      .header-btn {
        border: none;
        background: none;
        color: $light-tirkizna;
        transition: all 0.5s;
        span {
          font-size: 2.2rem;
        }
        &:hover {
          color: $light-green;
        }
      }
    }
  }
  .header-search-bar {
    width: 100%;
    display: flex;
    align-items: center;
    margin: 10px auto;
    padding: 5px 10px;
    background-color: $light-gray;
    border-radius: 5px;
    gap: 10px;

    input {
      width: 100%;
      background: none;
      border: none;
      font-size: 0.9rem;
      padding: 10px;
      &:focus {
        color: $gray;
        outline: none;
      }
    }
  }
}

/* TABLET */
@media only screen and (min-width: 700px) {
  header {
    .nav-links {
      padding: 20px 15px 30px;
      li {
        margin-bottom: 9px;
        a {
          font-size: 1.2rem;
          font-weight: 500;
        }
      }
    }
    .submenu {
      li {
        .submenu-links {
          color: #ccccccd4;
          font-size: 1.15rem;
        }
      }
    }
    .navbar-links {
      font-weight: 500;
      font-size: 1.2rem;
    }
    .social-links {
      margin-top: 10px;
    }
  }
}

/* *****DESKTOP***** */
@media only screen and (min-width: 1024px) {
  header {
    padding: 20px 10%;
    .nav-links {
      width: 45%;
    }
    .night-mode-btn {
      right: 10%;
    }
  }
}
